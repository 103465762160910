import React, {useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Loading} from "../common/Loading";
import {useGetData} from "../../api/useFetch";
import {getCurrentUser} from "../CommonFunctions";
import {Error} from "../common/Error";
import {CELL_TYPES} from "../../services/FarmService";
import {formatDataBySystemCode, getSingleInfluxdbDataBySystemCode} from "../../api/apiInfluxdb";
import {sendFarmCommandEvent} from "../../services/socketEventsService";

let startWeighingDate = 0;
let measuredWeight;

export const WeighResource = (props) => {

    const [isWeighing, setIsWeighing] = useState(false);

    const {successCallback, errorCallback, dialogOpen, setDialogOpen, setWeightMeasure} = props;

    const handleClose = () => {
        setIsWeighing(false);
        setDialogOpen(false);
    };

    const handleTare = (systemCode) => {
        sendFarmCommandEvent(systemCode, 'tare', 1);
        successCallback('The command has been sent.');
    };

    const handleWeigh = async (systemCode) => {
        setIsWeighing(true);
        startWeighingDate = Math.floor(Date.now() / 1000);
        sendFarmCommandEvent(systemCode, 'get_weight', 1);

        setTimeout(async () => {
            let lastMeasureTakenDate = 0;
            let tentativesNumber = 0;

            while(true){
                let weighResponse = await getSingleInfluxdbDataBySystemCode([systemCode], ['weight'])
                weighResponse = formatDataBySystemCode(weighResponse);

                if (weighResponse[systemCode]?.weight){
                    lastMeasureTakenDate = Math.floor(weighResponse[systemCode].time / 1000);

                    if(lastMeasureTakenDate > startWeighingDate)
                        measuredWeight = weighResponse[systemCode].weight;
                }

                if(measuredWeight)
                    break;

                else {
                    tentativesNumber++;
                    if (tentativesNumber === 3)
                        break;

                    // Equivalent to sleep(2000)
                    await new Promise(r => setTimeout(r, 2000));
                }
            }

            if(measuredWeight){
                setWeightMeasure(parseInt(measuredWeight));
            } else{
                errorCallback('Unable to weigh the resource.');
            }
            handleClose();
        }, 8000);
    };

    const GetWeighButtons = (props) => {
        const {structures} = props;

        if(structures.isLoading) return <Loading inline/>
        if(structures.isError) return <Error errorMessage={structures.error}/>

        if(structures.data.length === 0)
            return <Error errorMessage="This application is not linked to a Handddle Smart Farm."/>;

        /* Create balances buttons based on the Farm structure */
        let buttons = [];
        let balancesCount = 0;
        const farmModules = structures.data[0].farm_modules;

        for(let i=0; i<farmModules.length; i++){
            const balances = farmModules[i].farm_cells.filter(cell => cell.system_type === CELL_TYPES.BALANCE);

            for(let j=0; j<balances.length; j++){
                buttons.push(<button key={'tare-' + balancesCount} onClick={() => handleTare(balances[j].system_code)} autoFocus>Tare [{balancesCount+1}]</button>);
                buttons.push(<button key={'weigh-' + balancesCount} onClick={() => handleWeigh(balances[j].system_code)} autoFocus>Weigh [{balancesCount+1}]</button>);
                balancesCount++;
            }
        }

        return balancesCount > 0 ? <>{buttons}</> :
            <Error errorMessage="No connected balance."/>;
    };

    /* Organization selection */
    const currentUser = getCurrentUser();
    let selectedOrganization = localStorage.getItem('selected-organization');

    if(selectedOrganization === undefined || selectedOrganization === null || selectedOrganization === 'null')
        selectedOrganization = currentUser.organization.id;
    else
        selectedOrganization = parseInt(selectedOrganization);

    /* Get the balances count from the Farm structure */
    const structures = useGetData('farmStructure', 'farm_structures', {organization: selectedOrganization});

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Measure of the remaining quantity
            </DialogTitle>
            {
                isWeighing ?
                    <DialogContent>
                        <Loading/>
                        <center>Weighing in progress...</center>
                    </DialogContent>
                :
                    <>
                        <DialogContent>
                            <p>
                                Set the resource on the balance.<br/>
                                Once you are ready, click on the "Weigh resource" button.
                            </p>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={handleClose}>Cancel</button>
                            <GetWeighButtons structures={structures}/>
                        </DialogActions>
                    </>
            }
        </Dialog>
    )
}
