import React, {useEffect, useState} from "react";
import {postResource} from "../../../api/apiResource";
import {useForm, Controller} from "react-hook-form";
import {Drawer, makeStyles} from '@material-ui/core';
import {Loading} from "../../common/Loading";
import {Error} from "../../common/Error";
import {WeighResource} from "../WeighResource";

const useStyles = makeStyles({
    paper: {
        left: "50%"
    }
});

export const AddResourceComponent = (props) => {
    const classes = useStyles();

    const {colors, brands, models, materials, technologies, successCallback, errorCallback,
        rightPartAddIsOpen, setRightPartAddIsOpen, batchNumber, resourceDataToAdd, setResourceDataToAdd} = props;

    const {handleSubmit, control, setValue, reset} = useForm();

    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedTechnology, setSelectedTechnology] = useState(null);
    const [hasDiameter, setHasDiameter] = useState(false);
    const [hasColor, setHasColor] = useState(false);
    const [filteredTechnologies, setFilteredTechnologies] = useState([]);

    const [isWeighOpen, setIsWeighOpen] = useState(false);
    const [weightMeasure, setWeightMeasure] = useState(null);

    /* Set resourceDataToAdd values to form (resourceDataToAdd = values from table row) */
    useEffect(() => {
        if(resourceDataToAdd !== null && resourceDataToAdd !== undefined) {
            resetForm();

            let availableTechnologies = [];
            // Find corresponding brand
            for(let i = 0; i < brands.data.length; i++) {
                if(brands.data[i].id === resourceDataToAdd.brand.id) {
                    setValue('brand', JSON.stringify(brands.data[i]));
                    setSelectedBrand(brands.data[i]);
                    availableTechnologies = filterTechnologiesDependingOnBrand(brands.data[i]);
                    setFilteredTechnologies(availableTechnologies);
                    break;
                }
            }
            // Get data from first resourceDataToAdd child
            if(resourceDataToAdd.children[0]) {
                for(let i = 0; i < availableTechnologies.length; i++) {
                    if(availableTechnologies[i].id === resourceDataToAdd.children[0].material.technology.id) {
                        // Handle technology value
                        setValue('technology', JSON.stringify(availableTechnologies[i]));
                        setSelectedTechnology(availableTechnologies[i]);
                        setHasDiameter(availableTechnologies[i].has_diameter);
                        setHasColor(availableTechnologies[i].has_color);
                        // Set other values
                        setValue('material', resourceDataToAdd.children[0].material.id);
                        if(availableTechnologies[i].has_color) {
                            setValue('color', resourceDataToAdd.children[0].color.id);
                        }
                        if(availableTechnologies[i].has_diameter) {
                            setValue('diameter', resourceDataToAdd.children[0].diameter);
                        }
                        break;
                    }
                }
            }
        }
    }, [resourceDataToAdd]);

    /* If batch number in url, reset form */
    useEffect(() => {
        if(batchNumber !== undefined) {
            resetForm();
        }
    }, [batchNumber]);

    const onSubmit = async (dataResource) => {
        postResource(dataResource)
            .then(() => {
                // Reset form and states
                resetForm();
                setResourceDataToAdd(null);
                successCallback('Material has been added.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    }

    /* Reset all form values and associated states */
    const resetForm = () => {
        reset();
        setSelectedBrand(null);
        setSelectedTechnology(null);
        setHasDiameter(false);
        setHasColor(false);
        setFilteredTechnologies([]);
        setWeightMeasure(null);
    }

    const handleBrandChange = (e) => {
        e.preventDefault();
        let brand = JSON.parse(e.target.value);
        setSelectedBrand(brand);

        // Reset technology and material values
        resetTechnologyValue();
        setValue('material', 0);

        // Set technology list depending on selected brand
        setFilteredTechnologies(filterTechnologiesDependingOnBrand(brand));

        return e.target.value;
    }

    const resetTechnologyValue = () => {
        setSelectedTechnology(null);
        setHasDiameter(false);
        setHasColor(false);
        setValue('technology', 0);
    }

    const filterTechnologiesDependingOnBrand = (brand) => {
        if(brand.technologies !== undefined) {
            return technologies.data.filter(technology => {
                for(let i = 0; i < brand.technologies.length; i++) {
                    if(brand.technologies[i].id === technology.id) {
                        return technology;
                    }
                }
            });
        }
    }

    const handleTechnologyChange = (e) => {
        e.preventDefault();
        let technology = JSON.parse(e.target.value);

        //Update the diameter and color field
        setSelectedTechnology(technology);
        setHasDiameter(technology.has_diameter);
        setHasColor(technology.has_color);

        //Update the material field value with the first with the same technology
        let firstMaterialWithTechnology;
        materials.data.map((material) => {
            if(material.technology.id === technology.id && !firstMaterialWithTechnology)
                firstMaterialWithTechnology = material.id;
        });
        setValue('material', firstMaterialWithTechnology);

        return e.target.value;
    }

    const handleMaterialChange = (e) => {
        e.preventDefault();
        setValue('material', e.target.value);

        return e.target.value;
    }

    const handleDrawerClose = () => {
        setResourceDataToAdd(null);
        setRightPartAddIsOpen(false)
    }

    const handleSetWeightMeasure = (weight) => {
        setWeightMeasure(weight);
        setValue('remaining_quantity', weight);
    }

    return (
        <Drawer anchor="right" open={rightPartAddIsOpen} onClose={() => handleDrawerClose()} classes={{paper: classes.paper}}>
            {
                colors.isLoading || brands.isLoading || models.isLoading || materials.isLoading || technologies.isLoading ? <Loading/> :
                    colors.isError || brands.isError || models.isError || materials.isError || technologies.isError ? <Error/> :

                        <div id="right-part__container" className="right-part__container">
                            <div id="main__add-resource">
                                <div className="block">
                                    <h2>Add a material</h2>

                                    <h3>Material data</h3>

                                    {isWeighOpen &&
                                        <WeighResource
                                            successCallback={successCallback}
                                            errorCallback={errorCallback}
                                            dialogOpen={isWeighOpen} setDialogOpen={setIsWeighOpen}
                                            setWeightMeasure={handleSetWeightMeasure}
                                        />
                                    }

                                    <form id="add-resource__form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <div>
                                                <label htmlFor="f-brand-id">Brand</label>
                                                <Controller
                                                    control={control}
                                                    name="brand"
                                                    rules={{required: true, validate: (value) => value !== 0}}
                                                    defaultValue={0}
                                                    render={({field}) => (
                                                        <select id="f-brand-id" {...field} onChange={(e) => field.onChange(handleBrandChange(e))}>
                                                            <option disabled value={0}>Select a brand</option>
                                                            {brands.data.map((brand) => (
                                                                <option key={brand.id} value={JSON.stringify(brand)}>{brand.name}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />

                                                <label htmlFor="f-technology-id">Technology</label>
                                                <Controller
                                                    control={control}
                                                    name="technology"
                                                    rules={{required: true, validate: (value) => value !== 0}}
                                                    defaultValue={0}
                                                    render={({field}) => (
                                                        <select id="f-technology-id" {...field}
                                                                onChange={(e) => field.onChange(handleTechnologyChange(e))}
                                                                disabled={selectedBrand === null}>
                                                            <option disabled selected value={0}>Select a technology</option>
                                                            {filteredTechnologies.map((technology) => (
                                                                <option key={technology.id} value={JSON.stringify(technology)}>{technology.name}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />

                                                <label htmlFor="f-material-id">Material</label>
                                                <Controller
                                                    control={control}
                                                    name="material"
                                                    rules={{required: true, validate: (value) => value !== 0}}
                                                    defaultValue={0}
                                                    render={({field}) => (
                                                        <select id="f-material-id" {...field}
                                                                disabled={selectedTechnology === null}
                                                                onChange={(e) => field.onChange(handleMaterialChange(e))}>
                                                            <option disabled selected value={0}>Select a material</option>
                                                            {materials.data.map((material) => {
                                                                if(selectedTechnology && material.technology.id === selectedTechnology.id){
                                                                    return (
                                                                        <option key={material.id} value={material.id}>{material.name}</option>
                                                                    )
                                                                }
                                                            })}
                                                        </select>
                                                    )}
                                                />
                                            </div>
                                            <div>
                                                { hasColor &&
                                                    <div id="color-zone">
                                                        <label htmlFor="f-color-id">Color</label>
                                                        <Controller
                                                            control={control}
                                                            name="color"
                                                            rules={{required: hasColor}}
                                                            render={({field}) => (
                                                                <select id="f-color-id" {...field}>
                                                                    <option disabled selected value>Select a color</option>
                                                                    {colors.data.map((color) => (
                                                                        <option key={color.id} value={color.id}>{color.color_name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        />
                                                    </div>
                                                }

                                                { hasDiameter &&
                                                    <div id="diameter-zone" >
                                                        <label htmlFor="f-diameter">Filament diameter</label>
                                                        <Controller
                                                            control={control}
                                                            name="diameter"
                                                            rules={{required: hasDiameter}}
                                                            render={({field}) => (
                                                                <select id="f-diameter" {...field}>
                                                                    <option disabled selected value>Select a diameter</option>
                                                                    {selectedTechnology?.diameters.map((diameter, key) => (
                                                                        <option key={key} value={key}>{diameter} mm</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <h3>Resource quantity</h3>
                                        <p className="weigh-text-info">Weigh the resource to get the initial weight quantity</p>

                                        <button className="btn-white" type="button" onClick={() => setIsWeighOpen(true)}>
                                            <i className="fa fa-circle"/> Weigh
                                        </button>
                                        <p>{weightMeasure !== null ? <>Weight: {weightMeasure}g</> : <em>No measure taken yet</em>}</p>

                                        <h3>Resource data</h3>
                                        <div className="form-group">
                                            <div>
                                                <label htmlFor="f-serial-number">Batch number</label>
                                                <Controller
                                                    control={control}
                                                    name="serial_number"
                                                    rules={{required: true}}
                                                    defaultValue={batchNumber ? batchNumber : ''}
                                                    render={({field}) => (
                                                        <input type="text" id="f-serial-number" placeholder="Material batch number" {...field}/>
                                                    )}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="f-copies-number">Number of copy(ies)</label>
                                                <Controller
                                                    control={control}
                                                    name="copies_number"
                                                    defaultValue="1"
                                                    render={({field}) => (
                                                        <input type="number" id="f-copies-number" min="1" step="1" placeholder="Number of copy" {...field}/>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <button className="btn-white"><i className="fa fa-check"/> Add</button>
                                    </form>
                                </div>
                            </div>
                        </div>
            }
        </Drawer>
    )
}
