import {getFarmVersion, SMART_FARM_DATA_FIELDS} from "../../../services/FarmService";
import {FarmManagerBlock} from "./blocks/FarmManagerBlock";

export const FarmDialogStructure = (props) => {

    const {getChartsDataRangeBlock, getTemperatureBlock, getHumidityBlock, getPollutionBlock, farmVersion,
        isGeneralBuzzerOn, generalCommandsInProcess, setGeneralCommandsInProcess, getMaintenanceBlock,
        structureMaintenance, structureId, isFarmDataGeneralMaintenance, farmStructure} = props;

    const isEnvironmentDataAvailable = farmVersion && getFarmVersion(farmVersion) === 2;

    return(
        <div className="farm-dialog-body">

            <FarmManagerBlock
                isGeneralBuzzerOn={isGeneralBuzzerOn} setGeneralCommandsInProcess={setGeneralCommandsInProcess}
                generalCommandsInProcess={generalCommandsInProcess} farmStructure={farmStructure}
            />

            {getMaintenanceBlock(structureMaintenance, null, structureId, null, isFarmDataGeneralMaintenance)}

            {/*{isEnvironmentDataAvailable &&*/}
            {/*    <>*/}
            {/*        {getChartsDataRangeBlock(SMART_FARM_DATA_FIELDS.VOC_EXT)}*/}
            {/*        {getTemperatureBlock(false)}*/}
            {/*        {getHumidityBlock()}*/}
            {/*        {getPollutionBlock(false)}*/}
            {/*    </>*/}
            {/*}*/}
        </div>
    );
}